<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col v-if="!isMerchant" :span="8">
          <a-form-item label="商户编号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-input placeholder="请输入商户编号" v-model="queryParam.userNo" style="width: 240px"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="创建时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <!-- <a-date-picker v-model="queryParam.beginTime" style="width: 200px" /> -->
            <a-range-picker v-model="queryParam.createTime" style="width: 240px">
              <!-- <a-icon slot="suffixIcon" type="smile" /> -->
            </a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="成功时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <!-- <a-date-picker v-model="queryParam.endTime" style="width: 200px" /> -->
            <a-range-picker v-model="queryParam.successTime" style="width: 240px">
              <!-- <a-icon slot="suffixIcon" type="smile" /> -->
            </a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="商户订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-input placeholder="商户订单号" v-model="queryParam.openIdentifyNo" style="width: 240px"></a-input>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="平台订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-input placeholder="平台订单号" v-model="queryParam.orderNo" style="width: 240px"></a-input>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="代收状态:" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-select v-model="queryParam.status" style="width: 240px">
              <a-select-option v-for="item in status" :key="item.itemValue" :value="item.itemValue">
                {{ item.itemText }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" v-if="isMerchant">
          <span style="display: flex" class="table-page-search-submitButtons">
            <a-button
              type="primary"
              style="margin-left: 30px"
              @click="
                () => {
                  queryParam.pageNum = 1
                  searchQuery()
                }
              "
              icon="search"
              >查询</a-button
            >
            <a-button type="primary" style="margin-left: 20px" @click="reset">重置</a-button>
            <a-button type="primary" style="margin-left: 20px" @click="onExport">导出</a-button>
            <a-button type="primary" style="margin-left: 20px" @click="onNotifyAll">通知</a-button>
          </span>
        </a-col>
      </a-row>
      <a-row v-if="!isMerchant">
        <a-col :span="8" :offset="16">
          <span style="display: flex" class="table-page-search-submitButtons">
            <a-button
              type="primary"
              style="margin-left: 30px"
              @click="
                () => {
                  queryParam.pageNum = 1
                  searchQuery()
                }
              "
              icon="search"
              >查询</a-button
            >
            <a-button type="primary" style="margin-left: 20px" @click="reset">重置</a-button>
            <a-button type="primary" style="margin-left: 20px" @click="onExport">导出</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      :row-selection="rowSelection"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <!-- <a
          style="
            border-radius: 14px;
            height: 28px;
            border: 1px solid #8fa2f9;
            padding: 2px 6px;
            line-height: 28px;
            margin-right: 10px;
          "
          @click="handleEdit(record)"
        >
          凭证
        </a> -->
        <a
          @click="handleEdit2(record)"
          style="border-radius: 14px; height: 28px; border: 1px solid #8fa2f9; padding: 2px 6px; line-height: 28px"
        >
          更多
        </a>
        <a
          @click="handleNotify(record.orderId)"
          style="
            border-radius: 14px;
            height: 28px;
            border: 1px solid #8fa2f9;
            padding: 2px 6px;
            margin-left: 10px;
            line-height: 28px;
          "
        >
          通知
        </a>
      </span>
      >
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启动' : status == 2 ? '禁用' : '无状态' }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal
      :visible="modalvisible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :footer="null"
      width="400px"
    >
      <div class="modalContent">
        <div class="Item">
          <div class="left" style="color: #7d96f8">状态</div>
          <div class="right">{{ pinZ.statusText }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #a7e8c3">代付金额</div>
          <div class="right" style="color: #a7e8c3">{{ pinZ.amount }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #a7e8c3">手续费</div>
          <div class="right" style="color: #a7e8c3">{{ pinZ.fee }}</div>
        </div>
        <div class="Item">
          <div class="left">申请时间</div>
          <div class="right">{{ pinZ.createTime }}</div>
        </div>
        <div class="Item">
          <div class="left">完成时间</div>
          <div class="right">{{ pinZ.settleTime }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #5974f6">交易中心</div>
          <div class="right"></div>
        </div>

        <div class="Item">
          <div class="left">平台订单</div>
          <div class="right">{{ pinZ.orderNo }}</div>
        </div>
        <div class="Item">
          <div class="left">银行名称</div>
          <div class="right">{{ pinZ.bankName }}</div>
        </div>
        <div class="Item">
          <div class="left">银行编码</div>
          <div class="right">{{ pinZ.bankCode }}</div>
        </div>
        <div class="Item">
          <div class="left">收款人姓名</div>
          <div class="right">{{}}</div>
        </div>
        <div class="Item">
          <div class="left">收款账户</div>
          <div class="right">{{ pinZ.bankAccount }}</div>
        </div>
        <div class="Item">
          <div class="left">备注</div>
          <div class="right">{{ pinZ.remark }}</div>
        </div>
      </div>
    </a-modal>
    <a-modal :visible="modalvisible2" @cancel="handleCancel2" :footer="null" width="1000px">
      <template>
        <a-descriptions title="更多" layout="vertical" bordered :column="2">
          <a-descriptions-item label="失败编码">
            {{ more.failureCode }}
          </a-descriptions-item>
          <a-descriptions-item label="失败原因">
            {{ more.failureMessage }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  getOrderPage,
  findDictItemByCode,
  getOrderById,
  exportPlatformOrderList,
  notifyPayInOrders,
} from '../../../api/pay.js'
import { exportFile } from '@/api/exportFile'
import moment from 'moment'

var statusArr = []
export default {
  name: 'PayInOrderList',
  data() {
    return {
      queryParam: {
        userNo: '', //商户编号
        orderNo: '', //平台订单号
        openIdentifyNo: '', //商户订单号
        beginTime: '', //开始时间
        endTime: '', //结束时间
        beginFinishTime: '',
        endFinishTime: '',
        createTime: '',
        successTime: '',
        status: '', //代付状态
        pageNum: 1,
        pageSize: 10,
        userId: '',
        transType: 'payIn',
      },
      selectedRowKeys: [],
      isMerchant: sessionStorage.getItem('roleCodes')
        ? sessionStorage.getItem('roleCodes').includes('merchant')
        : false,
      dataSource: [],
      loading: false,
      total: 0,
      columns: [
        // {
        //   title: '商户编号',
        //   align: 'center',
        //   width: '100px',
        //   dataIndex: 'userNo',
        // },
        {
          title: '商户订单号',
          align: 'center',
          width: '100px',
          dataIndex: 'openIdentifyNo',
        },
        {
          title: '平台订单号',
          align: 'center',
          width: '150px',
          dataIndex: 'orderNo',
        },
        {
          title: '申请交易金额',
          align: 'right',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'amount',
          customRender: (text) => {
            return this.formatPrice(text)
          },
        },
        {
          title: '实际交易金额',
          align: 'right',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'actualAmount',
          customRender: (text) => {
            return this.formatPrice(text)
          },
        },
        {
          title: '手续费',
          align: 'right',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'fee',
          customRender: (text) => {
            if (text == null) {
              return null
            }
            return Math.abs(text)
          },
        },
        {
          title: '代收状态',
          align: 'center',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'status',
          customRender: (text) => {
            let index = 0
            index = statusArr.findIndex((item) => {
              return text.toString() == item.itemValue
            })
            return statusArr[index].itemText
          },
        },
        {
          title: '创建时间',
          align: 'center',
          width: '100px',
          dataIndex: 'createTime',
          customRender(text) {
            return moment(text).format('YYYY-MM-DD HH:mm:SS')
          },
        },
        {
          title: '完成时间',
          align: 'center',
          width: '100px',
          dataIndex: 'finishTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '150px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      edituserId: '',
      status: [],
      confirmLoading: false,
      modalvisible: false,
      modalvisible2: false,
      edit: false,
      editUserNo: '',
      pinZ: {},
      more: {},
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRowKeys = selectedRows.map((item) => {
            return item.orderId
          })
          console.log(this.selectedRowKeys)
        },
      }
    },
  },
  created() {
    findDictItemByCode('orderStatus').then((res) => {
      this.status = res.data.orderstatus
      statusArr = res.data.orderstatus
    })

    this.queryParam.createTime = [moment().subtract(1, 'days'), moment().add(0, 'days')]
    this.searchQuery()
  },
  methods: {
    formatPrice(price) {
      if (price == null) {
        return null
      }
      return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    handleNotify(id) {
      notifyPayInOrders([id]).then((res) => {
        if (res.code === '2000') {
          this.$message.success('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onNotifyAll() {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.error('您还没有选择订单')
      }

      notifyPayInOrders([...this.selectedRowKeys]).then((res) => {
        if (res.code === '2000') {
          this.$message.success('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onExport() {
      this.loading = true
      const params = { ...this.queryParam }
      let timeStr = ''
      const { createTime, successTime } = params
      
      if (createTime.length) {
        params.beginTime = `${moment(createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endTime = `${moment(createTime[1]).format('YYYY-MM-DD')} 23:59:59`
        timeStr += params.beginTime + '~' + params.endTime
        delete params.createTime 
      }
      if (successTime.length) {
        params.beginFinishTime = moment(successTime[0]).format('YYYY-MM-DD 00:00:00') 
        params.endFinishTime = moment(successTime[1]).format('YYYY-MM-DD 23:59:59')
        delete params.successTime 
      }

      let fileName = '商户代收订单'
      exportPlatformOrderList(params)
        .then((res) => {
          exportFile(res, fileName + timeStr)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    reset() {
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.dataSource = []
      const ignoreFields = ['pageNum', 'pageSize', 'transType']

      for (let key in this.queryParam) {
        if (ignoreFields.indexOf(key) === -1) {
          this.queryParam[key] = ''
        }
      }
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum
      this.queryParam.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      // this.loading = true
      let data = new FormData()
      data.append('id', row.orderId)
      data.append('orderType', '1')
      getOrderById(data).then((res) => {
        this.pinZ = res.data
        let index = 0
        index = statusArr.findIndex((item) => {
          this.pinZ.statusText = statusArr[index].itemText
          return text.toString() == item.itemValue
        })
        this.loading = false
      })
      this.modalvisible = true
    },
    handleEdit2(row) {
      // this.loading = true
      let data = new FormData()
      data.append('id', row.orderId), data.append('orderType', '1')
      getOrderById(data).then((res) => {
        this.more = res.data
        let index = 0
        index = statusArr.findIndex((item) => {
          this.more.statusText = statusArr[index].itemText
          return text.toString() == item.itemValue
        })
        this.loading = false
      })
      this.modalvisible2 = true
    },
    handleCancel() {
      this.modalvisible = false
    },
    handleCancel2() {
      this.modalvisible2 = false
    },
    searchQuery() {
      this.loading = true
      const params = { ...this.queryParam }
      const { createTime, successTime } = params

      if (createTime.length) {
        params.beginTime = `${moment(createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endTime = `${moment(createTime[1]).format('YYYY-MM-DD')} 23:59:59`
      }
      delete params.createTime

      if (successTime.length) {
        params.beginFinishTime = moment(successTime[0]).format('YYYY-MM-DD 00:00:00')
        params.endFinishTime = moment(successTime[1]).format('YYYY-MM-DD 23:59:59')
        delete params.successTime
      }

      getOrderPage(params).then((res) => {
        try {
          this.dataSource = res.data.list
          this.total = res.data.page.total
          this.loading = false
        } catch (error) {
          this.$message.error(res.msg) 
        }
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
.modalContent {
  margin-top: 30px;
  .Item {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    margin-bottom: 16px;
    .left {
      margin-right: 100px;
      width: 80px;
      text-align: right;
    }
    .right {
      text-align: left;
    }
  }
}
.modalContent2 {
  display: flex;
  .c1 {
    .Item {
      .left {
        width: 100px;
        margin-right: 80px;
        text-align: left;
      }
      .right {
        margin-right: 100px;
      }
    }
  }
}
</style>
